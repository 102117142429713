import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicSelectableModule } from 'ionic-selectable';
import { CommonModule } from '@angular/common';

import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { Keyboard } from '@capacitor/keyboard';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';

import { WebdatarocksPivotModule } from 'ng-webdatarocks';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { SessionService } from './services/session.service';

//import { Browser } from '@capacitor/browser';



//import { PivotViewModule } from '@syncfusion/ej2-angular-pivotview';

//import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridModule } from 'ag-grid-angular';
//import { AllModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';

import { LicenseManager } from 'ag-grid-enterprise';

// Set your license key
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-041034 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Pranera Services and Solutions Pvt Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( PraneraOMS )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( PraneraOMS )_need_to_be_licensed___( PraneraOMS )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 May 2024 )____[v2]_MTcxNTY0MTIwMDAwMA==6fa0b1c86242deb13e8c6c8f4bb1c14d');


import * as moment from 'moment-timezone';




@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, 
  BrowserAnimationsModule,
  IonicModule.forRoot(), 
  AppRoutingModule,
 //Browser, 
  HttpClientModule, 
  WebdatarocksPivotModule,
  //PivotViewModule,
  NgxDatatableModule,
  NgxDaterangepickerMd.forRoot(),
  FormsModule,
  ReactiveFormsModule,
  IonicSelectableModule,
  CommonModule,
  AgGridModule
  ],
  providers: [
  //StatusBar,
  //SplashScreen,
  SessionService,
  Printer,
  BarcodeScanner,
  File,
  FileOpener,
  //Keyboard,
  //Keyboard,
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {
}
