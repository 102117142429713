import { Component, ViewChild } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
//import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
//import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';
import { AlertService } from './services/alert.service';
import { PrimeNGConfig } from 'primeng/api';
import { MenuController } from '@ionic/angular';
import { MenuService } from './services/menu.service';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  username;

  // get appPages() {
  //   return this.menuService.getMenuItems();
  // }

  public appPages = [
    { title: 'Home', url: '/select-home', icon: 'home', class:'fa fa-home'},
    { title: 'Inspection Dashboard', url: '/dashboard', icon: 'apps', class:'fa fa-tachometer' },
    { title: 'Production Dashboard', url: '/production-dashboard', icon: 'apps',  class:'fa fa-tachometer' },
    { title: 'Sales Dashboard', url: '/sales-dashboard', icon: 'apps',  class:'fa fa-tachometer' },
    // { title: 'Inspect FabricRoll', url: '/select-create-roll', icon: 'create' },
    // { title: 'Inspect Collar&Cuff', url: '/select-create-collar-cuff', icon: 'create' },
    { title: 'QI Fabric Report View', url: '/qi-report-view', icon: 'clipboard',  class:'fa fa-bookmark'},
    { title: 'QI CC Report View', url: '/qi-ccreport-view', icon: 'clipboard',  class:'fa fa-cc' },
    { title: 'Lab Orders View', url: '/lab-orders-view', icon: 'clipboard',  class:'fa fa-cc' },
    // { title: 'QI Fabric Report Summary', url: '/report-view-detail', icon: 'clipboard' },
    // { title: 'QI CC Report Summary', url: '/cc-report-view-detail', icon: 'clipboard' },
    // { title: 'QI Fabric Roll Wise Summary', url: '/roll-view', icon: 'newspaper' },
    { title: 'Sales Order', url: '/sales-order-view', icon: 'newspaper',  class:'fa fa-cubes'},
    { title: 'Production Roll Wise Summary', url: '/production-roll-summary', icon: 'newspaper',  class:'fa fa-cubes'},
    { title: 'Production Roll Report Wise Summary', url: '/production-roll-report-summary', icon: 'newspaper',  class:'fa fa-clipboard' },
    { title: 'Create Batch', url: '/create-batch', icon: 'cog',  class:'fa fa-barcode' },
    { title: 'Stock With CollarCuff', url: '/stock-with-collar-cuff', icon: 'cog' ,  class:'fa fa-archive'},
    { title: 'Pivot', url: '/pivot', icon: 'cog',  class:'fa fa-sitemap' },
    { title: 'Process Loss', url: '/process-loss', icon: 'cog',  class:'fa fa-tasks' },
    { title: 'Settings', url: '/settings', icon: 'settings' ,  class:'fa fa-cogs'},
    { title: 'Registration', url: '/registration', icon: 'apps' ,  class:'fa fa-registered'},
    { title: 'Users', url: '/users-list', icon: 'people',  class:'fa fa-users' },
  ];
  
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    private primengConfig: PrimeNGConfig,
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private authService: AuthenticationService,
    //private storage: NativeStorage,
    private navCtrl: NavController,
    private router: Router,
    private alertService: AlertService,   
    private menu: MenuController,
    private menuService: MenuService
  ) {
    this.initializeApp();
  }



    initializeApp() {
    this.platform.ready().then(() => {
      this.username = localStorage.getItem("USERNAME");
      console.log("initializeApp");
     
      //this.statusBar.styleDefault();
      // Commenting splashScreen Hide, so it won't hide splashScreen before auth check
      //this.splashScreen.hide();
      //this.authService.getToken();
      

    });
  }


  

  // When Logout Button is pressed 
  
  
  logout() {
   this.authService.logout().then(data => {
     console.log("logged out");
     this.navCtrl.navigateRoot('/login');
     window.location.reload();
   })
  }

  ngOnInit() {
        this.primengConfig.ripple = true;
    }

  
  

  

}
