import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


import { AuthGuard } from './guards/auth.guard';

import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  //{
    //path: 'folder/:id',
    //loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    //canLoad: [AuthGuard]
  //},
  // {
  //   path: 'intro',
  //   loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    //canLoad: [IntroGuard, AutoLoginGuard]
    canLoad: [AutoLoginGuard]
  },

  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'roll-details',
    loadChildren: () => import('./pages/roll-details/roll-details.module').then( m => m.RollDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'mistake-details',
    loadChildren: () => import('./pages/mistake-details/mistake-details.module').then( m => m.MistakeDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'report-view',
    loadChildren: () => import('./pages/report-view/report-view.module').then( m => m.ReportViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'report-view-detail',
    loadChildren: () => import('./pages/report-view-detail/report-view-detail.module').then( m => m.ReportViewDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'roll-view',
    loadChildren: () => import('./pages/roll-view/roll-view.module').then( m => m.RollViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'mistake-modal',
    loadChildren: () => import('./pages/mistake-modal/mistake-modal.module').then( m => m.MistakeModalPageModule)
  },
  {
    path: 'select-home',
    loadChildren: () => import('./pages/select-home/select-home.module').then( m => m.SelectHomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'select-create-roll',
    loadChildren: () => import('./pages/select-create-roll/select-create-roll.module').then( m => m.SelectCreateRollPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'roll-modal',
    loadChildren: () => import('./pages/roll-modal/roll-modal.module').then( m => m.RollModalPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'roll-summary-modal',
    loadChildren: () => import('./pages/roll-summary-modal/roll-summary-modal.module').then( m => m.RollSummaryModalPageModule)
  },
  {
    path: 'pivot',
    loadChildren: () => import('./pages/pivot/pivot.module').then( m => m.PivotPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'dashboardlive',
    loadChildren: () => import('./pages/dashboardlive/dashboardlive.module').then( m => m.DashboardlivePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'collar-cuff-home',
    loadChildren: () => import('./pages/collar-cuff-home/collar-cuff-home.module').then( m => m.CollarCuffHomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'select-create-collar-cuff',
    loadChildren: () => import('./pages/select-create-collar-cuff/select-create-collar-cuff.module').then( m => m.SelectCreateCollarCuffPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'collar-cuff-mistake-details',
    loadChildren: () => import('./pages/collar-cuff-mistake-details/collar-cuff-mistake-details.module').then( m => m.CollarCuffMistakeDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'collar-cuff-details',
    loadChildren: () => import('./pages/collar-cuff-details/collar-cuff-details.module').then( m => m.CollarCuffDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'cc-report-view-detail',
    loadChildren: () => import('./pages/cc-report-view-detail/cc-report-view-detail.module').then( m => m.CcReportViewDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'knit-collar-cuff-home',
    loadChildren: () => import('./pages/knit-collar-cuff-home/knit-collar-cuff-home.module').then( m => m.KnitCollarCuffHomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'select-knit-collar-cuff',
    loadChildren: () => import('./pages/select-knit-collar-cuff/select-knit-collar-cuff.module').then( m => m.SelectKnitCollarCuffPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'knit-collar-cuff-details',
    loadChildren: () => import('./pages/knit-collar-cuff-details/knit-collar-cuff-details.module').then( m => m.KnitCollarCuffDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'collar-cuff-knitting-modal',
    loadChildren: () => import('./pages/collar-cuff-knitting-modal/collar-cuff-knitting-modal.module').then( m => m.CollarCuffKnittingModalPageModule)
  },
  {
    path: 'create-batch',
    loadChildren: () => import('./pages/create-batch/create-batch.module').then( m => m.CreateBatchPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'production-dashboard',
    loadChildren: () => import('./pages/production-dashboard/production-dashboard.module').then( m => m.ProductionDashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'production-roll-summary',
    loadChildren: () => import('./pages/production-roll-summary/production-roll-summary.module').then( m => m.ProductionRollSummaryPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'production-roll-report-summary',
    loadChildren: () => import('./pages/production-roll-report-summary/production-roll-report-summary.module').then( m => m.ProductionRollReportSummaryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'stock-with-collar-cuff',
    loadChildren: () => import('./pages/stock-with-collar-cuff/stock-with-collar-cuff.module').then( m => m.StockWithCollarCuffPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'collar-cuff-stock-modal',
    loadChildren: () => import('./pages/collar-cuff-stock-modal/collar-cuff-stock-modal.module').then( m => m.CollarCuffStockModalPageModule),
  },
  {
    path: 'qi-report-view',
    loadChildren: () => import('./pages/qi-report-view/qi-report-view.module').then( m => m.QiReportViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'qi-ccreport-view',
    loadChildren: () => import('./pages/qi-ccreport-view/qi-ccreport-view.module').then( m => m.QiCCReportViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users-list',
    loadChildren: () => import('./pages/users-list/users-list.module').then( m => m.UsersListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user-roles-persmission',
    loadChildren: () => import('./pages/user-roles-persmission/user-roles-persmission.module').then( m => m.UserRolesPersmissionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'process-loss',
    loadChildren: () => import('./pages/process-loss/process-loss.module').then( m => m.ProcessLossPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sales-dashboard',
    loadChildren: () => import('./pages/sales-dashboard/sales-dashboard.module').then( m => m.SalesDashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sales-order-view',
    loadChildren: () => import('./pages/sales-order-view/sales-order-view.module').then( m => m.SalesOrderViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sales-order-item-view',
    loadChildren: () => import('./pages/sales-order-item-view/sales-order-item-view.module').then( m => m.SalesOrderItemViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sales-order-item-stock-view',
    loadChildren: () => import('./pages/sales-order-item-stock-view/sales-order-item-stock-view.module').then( m => m.SalesOrderItemStockViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sales-order-bmview',
    loadChildren: () => import('./pages/sales-order-bmview/sales-order-bmview.module').then( m => m.SalesOrderBMViewPageModule),
  
  },
  {
    path: 'sales-order-item-stock-roll-view',
    loadChildren: () => import('./pages/sales-order-item-stock-roll-view/sales-order-item-stock-roll-view.module').then( m => m.SalesOrderItemStockRollViewPageModule),

  },
  {
    path: 'lab-orders-view',
    loadChildren: () => import('./pages/lab-orders-view/lab-orders-view.module').then( m => m.LabOrdersViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'lab-rolls-view',
    loadChildren: () => import('./pages/lab-rolls-view/lab-rolls-view.module').then( m => m.LabRollsViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'lab-rolls-modal',
    loadChildren: () => import('./pages/lab-rolls-modal/lab-rolls-modal.module').then( m => m.LabRollsModalPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
