


import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { AuthenticationService } from './authentication.service';
import { Platform, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private inactivityTimeout: any;

  constructor(
     private authService: AuthenticationService,
     private navCtrl: NavController,
  ) {}

  startInactivityTimer(timeout: number) {
    // console.log("startInactivityTimer timeout",timeout);
   
    // // Use App plugin to detect app pause/resume events
    // App.addListener('appStateChange', (state) => {
    //   console.log("state", state);
    //   if (state.isActive) {
    //     console.log("now active")
    //     this.resetInactivityTimer();
    //   } else {
    //     // The app is in the background; you might want to handle this case differently
    //     console.log('App is in the background.');
    //     this.startInactivityTimers(timeout);

    

    //   }
    // });
  }

  startInactivity() {


    // // Use App plugin to detect app pause/resume events
    // App.addListener('appStateChange', (state) => {
    //   console.log("state", state);
    //   if (state.isActive) {
    //     console.log("now active")
    //     this.resetInactivityTimer();
    //   } else {
    //     // The app is in the background; you might want to handle this case differently
    //     console.log('App is in the background.');
    //     this.startInactivityTimers(300000);


    //   }
    // });
  }

  startInactivityTimers(timeout){
       // this.inactivityTimeout = setTimeout(() => {
       //    // Implement your logout logic here
       //    console.log('User inactive. Logging out.');
       //     this.authService.logout().then(data => {
       //       console.log("logged out");
       //       this.navCtrl.navigateRoot('/login');
       //       window.location.reload();
       //     })
       //  }, timeout);

  }

  resetInactivityTimer() {
    // console.log("resetInactivityTimer");
    // clearTimeout(this.inactivityTimeout);
    //this.startInactivityTimer(21600000);
  }
}