import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private toastController: ToastController) { }
  async presentToast(message: any) {
    console.log("presentToast");
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'middle',
      cssClass: 'my-customToast-class',
    });
    toast.present();
  }

  async presentNoInternetToast(message: any) {
    console.log("presentNoInternetToast");
    const toast = await this.toastController.create({
      message: message,
      //duration: 5000,
      //showCloseButton: true,
      //closeButtonText: 'X',
      position: 'middle',
      cssClass: 'my-customToast-class',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => { 
            //this.handlerMessage = 'Dismiss clicked'; 
          }
        }
      ]
      
    });
    toast.present();
    toast.onDidDismiss().then((val) => {  
       console.log('Toast Dismissed');   
     }); 
  }
}