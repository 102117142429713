import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuItems: any[] = [];

  getMenuItems() {
    return this.menuItems;
  }

  setMenuItems(items: any[]) {
    this.menuItems = items;
  }
}