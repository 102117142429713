import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, finalize } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { EnvService } from './env.service';
 
import { Preferences } from '@capacitor/preferences';
 
 
const TOKEN_KEY = 'my-token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
 
  constructor(private http: HttpClient,
    private env: EnvService) {
    this.loadToken();
  }

  IPADDRESS;
  localIP;
  local_IP;
  ipaddress;
 
  async loadToken() {
    const token = await Preferences.get({ key: TOKEN_KEY });    
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }
 
   logins(credentials: {email, password}): Observable<any> {
    console.log("credentials",credentials);
    return this.http.post(this.env.API_URL1 + 'api/request', credentials).pipe(
      map((data: any) => data.token),
      switchMap(token => {
        console.log("token",token);
        var setToken = 'Yes';
        return from(Preferences.set({key: TOKEN_KEY, value: setToken}));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }

  // login(credentials: {email, password}) {

  //   this.localIP = window.location.origin;
  //   console.log("localIP",this.localIP);
  //   this.local_IP = this.localIP.slice(7,20);
  //   this.ipaddress = this.local_IP;
  //   if(this.ipaddress != "172.16.18.39"){
  //   return this.http.post(this.env.API_URL1 + 'api/request',
  //     credentials).pipe(
  //     tap(_ => {  
  //       var setToken = 'Yes';
  //       this.isAuthenticated.next(true);
  //       console.log("this.isAuthenticated.next",this.isAuthenticated.next);
  //       return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
  //     })
  //     );

  //   }
  //   else{
  //    return this.http.post(this.env.API_URL + 'api/request',
  //     credentials).pipe(
  //     tap(_ => {      
  //       var setToken = 'Yes';
  //       this.isAuthenticated.next(true);
  //       console.log("this.isAuthenticated.next",this.isAuthenticated.next);
  //       return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
  //     })
  //     );


  //   }

  // }

  register(signcredentials: {username, email, password}) {

    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    return this.http.post(this.env.API_URL1 + 'api/register',
      signcredentials).pipe(
      tap(_ => {  
        // var setToken = 'Yes';
        // this.isAuthenticated.next(true);
        // console.log("this.isAuthenticated.next",this.isAuthenticated.next);
        // return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
      })
      );

    }
    else{
     return this.http.post(this.env.API_URL + 'api/register',
      signcredentials).pipe(
      tap(_ => {      
        // var setToken = 'Yes';
        // this.isAuthenticated.next(true);
        // console.log("this.isAuthenticated.next",this.isAuthenticated.next);
        // return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
      })
      );


    }

  }
 

  login(credentials: {email, password}) {

    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    return this.http.post(this.env.API_URL1 + 'api/login',
      credentials).pipe(
      // map((data: any) => {
      //   console.log("data",data);
      // }),
      tap(_ => {  
        var setToken = 'Yes';
        this.isAuthenticated.next(true);
        console.log("this.isAuthenticated.next",this.isAuthenticated.next);
        return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
      }),
      );

    }
    else{
     return this.http.post(this.env.API_URL + 'api/login',
      credentials).pipe(
      // map((data: any) => {
      //   console.log("data",data);
      // }),
      tap(_ => {      
        var setToken = 'Yes';
        this.isAuthenticated.next(true);
        console.log("this.isAuthenticated.next",this.isAuthenticated.next);
        return from(Preferences.set({key: TOKEN_KEY, value: setToken}));    
        
      })
      );


    }

  }
 
  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    console.log("this.isAuthenticated.next",this.isAuthenticated.next);
    var tokens = Preferences.remove({key: TOKEN_KEY});
    console.log("tokens",tokens);
    return Preferences.remove({key: TOKEN_KEY});
    //window.location.reload();
  }

  getMistakeDetailsFromDB(){
    //console.log("inside getAllItem");
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    return this.http.get(this.env.API_URL1+'api/getMistakeDetailsFromDB');
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    return this.http.get(this.env.API_URL+'api/getMistakeDetailsFromDB');
    }
  }

  getItemsFromDB(searchItemText){
    //console.log("inside getAllItem");
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getItemsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getItemsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
  }
  
  

  getAllItemsFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getItemsFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getItemsFromDB',
      {
      }
      );
    }

  }

  getAllSupplierFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllSupplierFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllSupplierFromDB',
      {
      }
      );
    }

  }

  getAllOperatorFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllOperatorFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllOperatorFromDB',
      {
      }
      );
    }

  }


  getAllWorkOrderFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllWorkOrderFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllWorkOrderFromDB',
      {
      }
      );
    }

  }

  getTextilesSalesData(){
      this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/dojobsSalesInvoiceItem',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/dojobsSalesInvoiceItem',
      {
      }
      );
    }

  }

  getAllJobCardFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllJobCardFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllJobCardFromDB',
      {
      }
      );
    }

  }

  getAllPurchaseOrderFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllPurchaseOrderFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllPurchaseOrderFromDB',
      {
      }
      );
    }

  }

  getAllSubcontractingOrderFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllSubcontractingOrderFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllSubcontractingOrderFromDB',
      {
      }
      );
    }

  }

  getAllPurchaseOrderFromDB1(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllPurchaseOrderFromDB1',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllPurchaseOrderFromDB1',
      {
      }
      );
    }

  }

  getAllSubcontractingOrderFromDB1(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getAllSubcontractingOrderFromDB1',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllSubcontractingOrderFromDB1',
      {
      }
      );
    }

  }

  getQIReportFromDB(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQIReportFromDB',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQIReportFromDB',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getAllQIReportFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    
   
    return this.http.get(this.env.API_URL1+'api/getAllQIReportFromDB',
      {
     
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     
    
    return this.http.get(this.env.API_URL+'api/getAllQIReportFromDB',
      {
      
      }
      );
    }

  }

  getAllQICCReportFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    
   
    return this.http.get(this.env.API_URL1+'api/getAllQICCReportFromDB',
      {
     
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     
    
    return this.http.get(this.env.API_URL+'api/getAllQICCReportFromDB',
      {
      
      }
      );
    }

  }

  getQICCReportFromDB(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromDB',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQICCReportFromDB',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


  getRollNoFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getRollNoFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getRollNoFromDB',
      {
      }
      );
    }

  }

  getPoNoFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getPoNoFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getPoNoFromDB',
      {
      }
      );
    }

  }





  getQIReportFromDBCompleted(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
       var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQIReportFromDBCompleted',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var jsonStartDate = searchStartDate;
      var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQIReportFromDBCompleted',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }
  getQICCReportFromDBCompleted(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromDBCompleted',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQICCReportFromDBCompleted',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getQICCReportFromDBAllData(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQICCReportFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getQIReportFromDBAllData(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQIReportFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQIReportFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

   getSalesOrderFromDBAllData(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getSalesOrderFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
       var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getSalesOrderFromDBAllData',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  submitUserRoles(jsonData){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonData = jsonData;
      //var jsonSelectedCategories = selectedCategories;
   
    return this.http.get(this.env.API_URL1+'api/submitUserRoles',
      {
      params:{
      jsonData:jsonData,
      
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonData = jsonData;
       
    return this.http.get(this.env.API_URL+'api/submitUserRoles',
      {
      params:{
      jsonData:jsonData,
      
      }
      }
      );
    }

  }
 

  getUserRoles(username){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonUsername = username;
   
    return this.http.get(this.env.API_URL1+'api/getUserRoles',
      {
      params:{
      jsonUsername:jsonUsername,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
        var jsonUsername = username;
       
    return this.http.get(this.env.API_URL+'api/getUserRoles',
      {
      params:{
      jsonUsername:jsonUsername,
      }
      }
      );
    }

  }

  
  getPagePermission(pageName){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;

    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonPageName = pageName;
    return this.http.get(this.env.API_URL1+'api/getPagePermission',
      {
      params:{
      jsonPageName:jsonPageName,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonPageName = pageName;
    return this.http.get(this.env.API_URL+'api/getPagePermission',
      {
      params:{
      jsonPageName:jsonPageName,
      }
      }
      );
    }

  }

  getUserData(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getUserData',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getUserData',
      {
      }
      );
    }

  }

  getGradeNo3RollReportFromERPNEXT(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getGradeNo3RollReportFromERPNEXT',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getGradeNo3RollReportFromERPNEXT',
      {
      }
      );
    }

  }

  getGradeNo2RollReportFromERPNEXT(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getGradeNo2RollReportFromERPNEXT',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getGradeNo2RollReportFromERPNEXT',
      {
      }
      );
    }

  }

  getGradeNo1RollReportFromERPNEXT(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getGradeNo1RollReportFromERPNEXT',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getGradeNo1RollReportFromERPNEXT',
      {
      }
      );
    }

  }

  getQIReportFromDBWithInspectNotOk(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getQIReportFromDBWithInspectNotOk',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQIReportFromDBWithInspectNotOk',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getQICCReportFromDBWithInspectNotOk(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
       var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromDBWithInspectNotOk',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    
    return this.http.get(this.env.API_URL+'api/getQICCReportFromDBWithInspectNotOk',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getQIReportFromDBWithInspectOk(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
        var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
   
    return this.http.get(this.env.API_URL1+'api/getQIReportFromDBWithInspectOk',
      {
        params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getQIReportFromDBWithInspectOk',
      {
        params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getAllQIReportFromDBWithInspectOk(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
       
   
    return this.http.get(this.env.API_URL1+'api/getAllQIReportFromDBWithInspectOk',
      {
       
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     
    return this.http.get(this.env.API_URL+'api/getAllQIReportFromDBWithInspectOk',
      {
       
      }
      );
    }

  }

  getQICCReportFromDBWithInspectOk(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromDBWithInspectOk',
      {
          params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getQICCReportFromDBWithInspectOk',
      {
          params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getAllQICCReportFromDBWithInspectOk(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     
    return this.http.get(this.env.API_URL1+'api/getAllQICCReportFromDBWithInspectOk',
      {
          
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getAllQICCReportFromDBWithInspectOk',
      {
         
      }
      );
    }

  }



  getKnitCCReportFromDB(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getKnitCCReportFromDB',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getKnitCCReportFromDB',
      {
      }
      );
    }

  }

  getAllJobCardForWOFromDB(searchWorkOrder){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonWorkOrder = searchWorkOrder;
    //console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getAllJobCardForWOFromDB',
      {
      params:{
      jsonWorkOrder:jsonWorkOrder,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonWorkOrder = searchWorkOrder;
    return this.http.get(this.env.API_URL+'api/getAllJobCardForWOFromDB',
      {
      params:{
      jsonWorkOrder:jsonWorkOrder,
      }
      });
    }

  }

  getAllSubcontractOrderForPOFromDB(searchPurchaseOrder){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonPurchaseOrder = searchPurchaseOrder;
    console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getAllSubcontractOrderForPOFromDB',
      {
      params:{
      jsonPurchaseOrder:jsonPurchaseOrder,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonPurchaseOrder = searchPurchaseOrder;
    return this.http.get(this.env.API_URL+'api/getAllSubcontractOrderForPOFromDB',
      {
      params:{
      jsonPurchaseOrder:jsonPurchaseOrder,
      }
      });
    }

  }

  getRollReportSummaryJobCardWiseFromERPNext(searchReportNo){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummaryJobCardWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    return this.http.get(this.env.API_URL+'api/getRollReportSummaryJobCardWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
  }

  updateRollDetails(jobCardWiseReports){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = jobCardWiseReports;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/updateRollDetails',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = jobCardWiseReports;
    return this.http.get(this.env.API_URL+'api/updateRollDetails',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
  }

  viewSummaryForJobCard(data){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummaryJobCardWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    return this.http.get(this.env.API_URL+'api/getRollReportSummaryJobCardWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }

  }

  viewSummaryForPurchaseOrder(data){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummaryPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    return this.http.get(this.env.API_URL+'api/getRollReportSummaryPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }

  }

  viewSummaryForSubcontractOrder(data){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummarySOPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = data;
    return this.http.get(this.env.API_URL+'api/getRollReportSummarySOPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }

  }

  getRollReportSummaryPOWiseFromERPNext(searchReportNo){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummaryPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    return this.http.get(this.env.API_URL+'api/getRollReportSummaryPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }

  }

  getRollReportSummarySOPOWiseFromERPNext(searchReportNo){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    //console.log("jsonPurchaseOrder",jsonPurchaseOrder);
    return this.http.get(this.env.API_URL1+'api/getRollReportSummarySOPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonReportNo = searchReportNo;
    return this.http.get(this.env.API_URL+'api/getRollReportSummarySOPOWiseFromERPNext',
      {
      params:{
      jsonReportNo:jsonReportNo,
      }
      });
    }

  }





  getTotalFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getTotalFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getTotalFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getTotalCCInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getTotalCCInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getTotalCCInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

    getMistakeWiseFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getMistakeWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getMistakeWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getMistakeWiseFinishFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getMistakeWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getMistakeWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

    getMistakeWiseSupplierWiseFinishFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getMistakeWiseSupplierWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getMistakeWiseSupplierWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }


    getSupplierWiseFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getSupplierWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSupplierWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

   getSupplierWiseFinishFabricInspected(searchStartDate, searchEndDate){
    console.log("inside getSupplierWiseFinishFabricInspected");
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getSupplierWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSupplierWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

   getFabricWiseFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getFabricWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getFabricWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }


   getFabricWiseFinishFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getFabricWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getFabricWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }


   getCheckerWiseFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getCheckerWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getCheckerWiseFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getCheckerWiseFinishFabricInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getCheckerWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getCheckerWiseFinishFabricInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getGradeWiseRollInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getGradeWiseRollInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getGradeWiseRollInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getGradeWiseFinishRollInspected(searchStartDate, searchEndDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    console.log("jsonStartDate",jsonStartDate);
    return this.http.get(this.env.API_URL1+'api/getGradeWiseFinishRollInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getGradeWiseFinishRollInspected',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      });
    }

  }

  getOnGoingInspectionDetails(todayDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getOnGoingInspectionDetails',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getOnGoingInspectionDetails',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }

  }

  getOnGoingCCInspectionDetails(todayDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getOnGoingCCInspectionDetails',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getOnGoingCCInspectionDetails',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }

  }


  getLiveFabricInspectionDetails(startdate, enddate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    //console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getLiveFabricInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate

      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    return this.http.get(this.env.API_URL+'api/getLiveFabricInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate
      }
      });
    }

  }

getLiveFabricMachineInspectionDetails(startdate, enddate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    //console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getLiveFabricMachineInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate

      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    return this.http.get(this.env.API_URL+'api/getLiveFabricMachineInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate
      }
      });
    }

  }


  getLiveCCInspectionDetails(startdate, enddate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    //console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getLiveCCInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate

      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    return this.http.get(this.env.API_URL+'api/getLiveCCInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate
      }
      });
    }

  }

  getLiveCCMachineInspectionDetails(startdate, enddate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    //console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getLiveCCMachineInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate

      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = startdate;
    var jsonEndDate = enddate;
    return this.http.get(this.env.API_URL+'api/getLiveCCMachineInspectionDetails',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate: jsonEndDate
      }
      });
    }

  }

  getTodayInspectedFabricWeight(todayDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getTodayInspectedFabricWeight',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayInspectedFabricWeight',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }

  }

  getTodayInspectedFabricTotalRolls(todayDate){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    console.log("jsonTodayDate",jsonTodayDate);
    return this.http.get(this.env.API_URL1+'api/getTodayInspectedFabricTotalRolls',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayInspectedFabricTotalRolls',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      });
    }

  }

  getTodayInspectedFabricTotalRollsForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayInspectedFabricTotalRollsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getTodayInspectedFabricTotalRollsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesInvoiceDetailsForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesOrderDetailsForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

   getSalesInvoiceDetailsForYesterdayDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForYesterdayDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForYesterdayDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

getSalesOrderDetailsForYesterdayDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForYesterdayDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForYesterdayDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }  

getSalesInvoiceDetailsForThisWeekDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForThisWeekDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForThisWeekDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesOrderDetailsForThisWeekDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForThisWeekDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForThisWeekDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }
  getSalesInvoiceDetailsForThisMonthDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForThisMonthDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForThisMonthDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesOrderDetailsForThisMonthDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForThisMonthDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForThisMonthDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesInvoiceDetailsForThisQuarterDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForThisQuarterDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForThisQuarterDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesOrderDetailsForThisQuarterDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForThisQuarterDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForThisQuarterDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesInvoiceDetailsForThisYearDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForThisYearDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForThisYearDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getSalesOrderDetailsForThisYearDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForThisYearDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForThisYearDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

    getSalesInvoiceDetailsForThisYearDateTrendChart(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesInvoiceDetailsForThisYearDateTrendChart',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesInvoiceDetailsForThisYearDateTrendChart',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


getSalesOrderDetailsForThisYearDateTrendChart(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     //var jsonTodayDate = todayDate;
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderDetailsForThisYearDateTrendChart',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonTodayDate = todayDate;
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getSalesOrderDetailsForThisYearDateTrendChart',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


  getTextilesSalesInvoiceCommercialNameWise(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesInvoiceCommercialNameWise',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesInvoiceCommercialNameWise',
      {
      }
      );
    }

  }

  getTextilesSalesOrderCommercialNameWise(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesOrderCommercialNameWise',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesOrderCommercialNameWise',
      {
      }
      );
    }

  }


getTextilesSalesInvoiceCustomerNameWise(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesInvoiceCustomerNameWise',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesInvoiceCustomerNameWise',
      {
      }
      );
    }

  }

  getTextilesSalesOrderCustomerNameWise(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesOrderCustomerNameWise',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesOrderCustomerNameWise',
      {
      }
      );
    }

  }

  getTextilesSalesInvoicePivotData(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesInvoicePivotData',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesInvoicePivotData',
      {
      }
      );
    }

  }

  getTextilesSalesOrderPivotData(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesOrderPivotData',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesOrderPivotData',
      {
      }
      );
    }

  }

  getTextilesSalesInvoicePivotDataForChart(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesInvoicePivotDataForChart',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesInvoicePivotDataForChart',
      {
      }
      );
    }

  }

  getTextilesSalesOrderPivotDataForChart(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/getTextilesSalesOrderPivotDataForChart',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/getTextilesSalesOrderPivotDataForChart',
      {
      }
      );
    }

  }

  getTodayInspectedCollarCuffTotalRollsForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayInspectedCollarCuffTotalRollsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getTodayInspectedCollarCuffTotalRollsForDate',
      {
     params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionFabricTotalRollsForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionFabricTotalRollsForDate',
      {
       params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionFabricTotalRollsForDate',
      {
       params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionFabricTotalRollsForSpecifiedDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionFabricTotalRollsForSpecifiedDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionFabricTotalRollsForSpecifiedDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionCollarCuffTotalRollsForSpecifiedDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffTotalRollsForSpecifiedDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffTotalRollsForSpecifiedDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionCollarCuffWiseTotalRollsForDate(todayDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }

  }

  getTodayProductionCollarCuffMachineWiseTotalRollsForDate(todayDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffMachineWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffMachineWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }

  }

  getTodayProductionCollarCuffMachineWiseDayWiseTotalRollsForDate(todayDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffMachineWiseDayWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffMachineWiseDayWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }

  }


  getTodayProductionFabricWiseTotalRollsForDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionFabricWiseTotalRollsForDate',
      {
       params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionFabricWiseTotalRollsForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


  getTodayProductionFabricDayWiseTotalRollsForDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionFabricDayWiseTotalRollsForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionFabricDayWiseTotalRollsForDate',
      {
       params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


  getTodayProductionFabricDayWiseTotalRollsForSpecifiedDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionFabricDayWiseTotalRollsForSpecifiedDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionFabricDayWiseTotalRollsForSpecifiedDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }


 getTodayProductionCollarCuffDayWiseTotalRollsForDate(todayDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffDayWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffDayWiseTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }

  }


  getTodayProductionCollarCuffDayWiseTotalRollsForSpecifiedDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCollarCuffDayWiseTotalRollsForSpecifiedDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCollarCuffDayWiseTotalRollsForSpecifiedDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }   

  getTodayProductionMachineWiseTotalRollsForDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionMachineWiseTotalRollsForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionMachineWiseTotalRollsForDate',
      {
       params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionMachineWiseDayWiseTotalRollsForDate(StartDate, EndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionMachineWiseDayWiseTotalRollsForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = StartDate;
    var jsonEndDate = EndDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionMachineWiseDayWiseTotalRollsForDate',
      {
       params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getTodayProductionCCTotalRollsForDate(todayDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL1+'api/getTodayProductionCCTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonTodayDate = todayDate;
    return this.http.get(this.env.API_URL+'api/getTodayProductionCCTotalRollsForDate',
      {
      params:{
      jsonTodayDate:jsonTodayDate,
      }
      }
      );
    }

  }




    getGradeNo3RollReportFromERPNEXTForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getGradeNo3RollReportFromERPNEXTForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getGradeNo3RollReportFromERPNEXTForDate',
      {
      params:{
      jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }

  getGradeNo2RollReportFromERPNEXTForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getGradeNo2RollReportFromERPNEXTForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getGradeNo2RollReportFromERPNEXTForDate',
      {
      params:{
       jsonStartDate:searchStartDate,
      jsonEndDate:searchEndDate,
      }
      }
      );
    }

  }

  getGradeNo1RollReportFromERPNEXTForDate(searchStartDate, searchEndDate){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
      var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL1+'api/getGradeNo1RollReportFromERPNEXTForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
     var jsonStartDate = searchStartDate;
    var jsonEndDate = searchEndDate;
    return this.http.get(this.env.API_URL+'api/getGradeNo1RollReportFromERPNEXTForDate',
      {
      params:{
       jsonStartDate:jsonStartDate,
      jsonEndDate:jsonEndDate,
      }
      }
      );
    }

  }




  getItemsDetailsForPOFromDB(searchItemDetailText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getItemsDetailsForPOFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getItemsDetailsForPOFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getItemsDetailsForSOFromDB(searchItemDetailText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getItemsDetailsForSOFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getItemsDetailsForSOFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getWorkOrderDetailsFromDB(searchWorkOrderText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchWorkOrderText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getWorkOrderDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchWorkOrderText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getWorkOrderDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
  }


  getJobCardDetailsFromDB(searchWorkOrderText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchWorkOrderText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getJobCardDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchWorkOrderText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getJobCardDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
  }

  getSpecifiedKnitCCReportFromDB(searchKnitCCReportText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchKnitCCReportText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getSpecifiedKnitCCReportFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchKnitCCReportText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getSpecifiedKnitCCReportFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getSpecifiedKnitCCReportRollsFromDB(searchKnitCCReportText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchKnitCCReportText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getSpecifiedKnitCCReportRollsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchKnitCCReportText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getSpecifiedKnitCCReportRollsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }



  getItemsBatchDetailsFromDB(searchItemDetailText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getItemBatchDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getItemBatchDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getItemsDetailsFromDB(searchItemDetailText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getItemsDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getItemsDetailsFromDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getSpecifiedItemsDetailsForPOFromDB(searchItemDetailText, searchPONoText){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    var jsonData1 = searchPONoText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getSpecifiedItemsDetailsForPOFromDB',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    var jsonData1 = searchPONoText;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getSpecifiedItemsDetailsForPOFromDB',
      {
      params:{
      jsonData:jsonData,
      jsonData1: jsonData1
      }
      });
    }

  }

   getSpecifiedItemsDetailsForSOFromDB(searchItemDetailText, searchSubcontractingOrder){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    var jsonData1 = searchSubcontractingOrder;
    console.log("jsonData",jsonData);
    console.log("jsonData1",jsonData1);
    return this.http.get(this.env.API_URL1+'api/getSpecifiedItemsDetailsForSOFromDB',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = searchItemDetailText;
    var jsonData1 = searchSubcontractingOrder;
    console.log("jsonData",jsonData);
    console.log("jsonData1",jsonData1);
    return this.http.get(this.env.API_URL+'api/getSpecifiedItemsDetailsForSOFromDB',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }

  }

  getGreigeRollNoInDB(finishRollNo){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonData = finishRollNo;
    var jsonData = localStorage.getItem("EDITFINISHROLLNO");
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getGreigeRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonData = finishRollNo;
    var jsonData = localStorage.getItem("EDITFINISHROLLNO");
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getGreigeRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  getGreigeCCRollNoInDB(finishRollNo){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    //var jsonData = finishRollNo;
    var jsonData = localStorage.getItem("EDITFINISHROLLNO");
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/getGreigeCCRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    //var jsonData = finishRollNo;
    var jsonData = localStorage.getItem("EDITFINISHROLLNO");
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/getGreigeCCRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }


  checkGreigeRollNoinFinishRoll(greigeRollNo){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/checkGreigeRollNoinFinishRoll',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/checkGreigeRollNoinFinishRoll',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  checkGreigeCCRollNoinFinishRoll(greigeRollNo){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/checkGreigeCCRollNoinFinishRoll',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/checkGreigeCCRollNoinFinishRoll',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  createFinishRollNoInDB(greigeRollNo){

    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/createFinishRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/createFinishRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  createCCFinishRollNoInDB(greigeRollNo){

    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/createCCFinishRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = greigeRollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/createCCFinishRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  createKnitCCRollNoInDB(reportNo){

    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = reportNo;
    //console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/createKnitCCRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = reportNo;
    //console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/createKnitCCRollNoInDB',
      {
      params:{
      jsonData:jsonData
      }
      });
    }

  }

  checkRollNoInERPNext(rollNo, reportNo){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = rollNo;
    var jsonData1 = reportNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/checkRollNoInERPNext',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = rollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/checkRollNoInERPNext',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }

  }



  checkCCRollNoInERPNext(rollNo, reportNo){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = rollNo;
    var jsonData1 = reportNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL1+'api/checkCCRollNoInERPNext',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = rollNo;
    console.log("jsonData",jsonData);
    return this.http.get(this.env.API_URL+'api/checkCCRollNoInERPNext',
      {
      params:{
      jsonData:jsonData,
      jsonData1:jsonData1
      }
      });
    }

  }

  getbatchFromDB(searchBatchText, searchItemText){
    //console.log("inside getAllItem");
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var searchBatchText = searchBatchText;
    var searchItemText = searchItemText;
    console.log("searchBatchText",searchBatchText);
    return this.http.get(this.env.API_URL1+'api/getbatchFromDB',
      {
      params:{
      searchBatchText:searchBatchText,
      searchItemText:searchItemText
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var searchBatchText = searchBatchText;
    var searchItemText = searchItemText;
    console.log("searchBatchText",searchBatchText);
    return this.http.get(this.env.API_URL+'api/getbatchFromDB',
      {
      params:{
      searchBatchText:searchBatchText,
      searchItemText:searchItemText
      }
      });
    }
  }


  getIndentsFromDB(searchIndentText, searchItemText){
    //console.log("inside getAllItem");
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var searchIndentText = searchIndentText;
    var searchItemText = searchItemText;
    console.log("searchIndentText",searchIndentText);
    return this.http.get(this.env.API_URL1+'api/getIndentsFromDB',
      {
      params:{
      searchIndentText:searchIndentText,
      searchItemText:searchItemText
      }
      });
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var searchIndentText = searchIndentText;
    var searchItemText = searchItemText;
    console.log("searchIndentText",searchIndentText);
    return this.http.get(this.env.API_URL+'api/getIndentsFromDB',
      {
      params:{
      searchIndentText:searchIndentText,
      searchItemText:searchItemText
      }
      });
    }
  }

  postFabricCollarCuffStockDetails(jsonData){
    console.log("jsonData",jsonData);
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL2+'api/postFabricCollarCuffStockDetails',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL2+'api/postFabricCollarCuffStockDetails',
    {
    params:{
    jsonData:jsonData
    }
    });

    }

  }

  getFabricCollarCuffStockDetails(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL2+'api/getFabricCollarCuffStockDetails',
      {
        params:{
        jsonData:jsonData
        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL2+'api/getFabricCollarCuffStockDetails',
      {
        params:{
        jsonData:jsonData
        }
      }
      );
    }

  }

  getStockDetailsForAllBatches(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllBatches',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllBatches',
      {
      }
      );
    }

  }

  getStockDetailsForAllBatchesJV(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllBatchesJV',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllBatchesJV',
      {
      }
      );
    }

  }
 
 updateStockEntryLinkedSalesOrder(){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL1+'api/updateStockEntryLinkedSalesOrder',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL+'api/updateStockEntryLinkedSalesOrder',
      {
      }
      );
    }

 }

 callSetWarehouseWiseStockData(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   //callSetWarehouseWiseStockData
    return this.http.get(this.env.API_URL2+'api/callSetWarehouseWiseStockData',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL2+'api/callSetWarehouseWiseStockData',
      {
      }
      );
    }

  }

 getStockDetailsForAll(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   //callSetWarehouseWiseStockData
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAll',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAll',
      {
      }
      );
    }

  }



  getStockDetailsForAllJV(){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
   
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllJV',
      {
      }
      );
    }
    else{
      console.log("this.ipaddress else",this.ipaddress);
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForAllJV',
      {
      }
      );
    }

  }

  postData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postBatchData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postBatchData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postBatchData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }


    postCCData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postCCData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postCCData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postKnitCCData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postKnitCCData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postKnitCCData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postKnitCCDataRoll(jsonData){
    console.log("jsonData",jsonData);  
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postKnitCCDataRoll',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postKnitCCDataRoll',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postRollData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postCollarCuffRollData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postCollarCuffRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postCollarCuffRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }


    postKnitCollarCuffRollData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postKnitCollarCuffRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postKnitCollarCuffRollData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postCCRollSummaryData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postCCRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postCCRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postRollSummaryData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postKnitCCRollSummaryData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postKnitCCRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postKnitCCRollSummaryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }


    getQIReportFromERPNEXT(report_no, roll_no){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var report_no = report_no;
    var roll_no = roll_no;
    return this.http.get(this.env.API_URL1+'api/getQIReportFromERPNEXT',
    {
    params:{
    report_no:report_no,
    roll_no: roll_no
    }
    });

    }
    else{
    var report_no = report_no;
    var roll_no = roll_no;
    return this.http.get(this.env.API_URL+'api/getQIReportFromERPNEXT',
    {
    params:{
    report_no:report_no,
    roll_no: roll_no
    }
    });

    }
   
    }

    getStockEntryLinkedSalesOrder(report_no){
        this.localIP = window.location.origin;
        console.log("localIP",this.localIP);
        this.local_IP = this.localIP.slice(7,20);
        this.ipaddress = this.local_IP;
        if(this.ipaddress != "172.16.18.39"){
        var report_no = report_no;
        return this.http.get(this.env.API_URL1+'api/getStockEntryLinkedSalesOrder',
        {
        params:{
        report_no:report_no,
        }
        });

        }
        else{
        var report_no = report_no;
        return this.http.get(this.env.API_URL+'api/getStockEntryLinkedSalesOrder',
        {
        params:{
        report_no:report_no,
        }
        });

        }

    }

    getSalesOrderItemsFromDB(report_no){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var report_no = report_no;
    return this.http.get(this.env.API_URL1+'api/getSalesOrderItemsFromDB',
    {
    params:{
    report_no:report_no,
    }
    });

    }
    else{
    var report_no = report_no;
    return this.http.get(this.env.API_URL+'api/getSalesOrderItemsFromDB',
    {
    params:{
    report_no:report_no,
    }
    });

    }
   
    }

    getCollarCuffDetails(name, warehouse){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var name = name;
    var warehouse = warehouse;
    
    return this.http.get(this.env.API_URL2+'api/getCollarCuffDetails',
    {
    params:{
    name:name,
    warehouse:warehouse
    
    }
    });

    }
    else{
    var name = name;
    var warehouse = warehouse;
    return this.http.get(this.env.API_URL2+'api/getCollarCuffDetails',
    {
    params:{
    name:name,
    warehouse:warehouse
   
    }
    });

    }

    }
    

  updateTransferredQtyInSalesOrderItem(filteredArray, filteredStockEntryDataForSalesOrder, stockEntryDoc){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var filteredArray = filteredArray;
     console.log("filteredArray1",filteredArray);
    return this.http.get(this.env.API_URL1+'api/updateTransferredQtyInSalesOrderItem',
      {
        params:{
          filteredArray:filteredArray,
          filteredStockEntryDataForSalesOrder: filteredStockEntryDataForSalesOrder,
          stockEntryDoc: stockEntryDoc
        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var filteredArray = filteredArray;
       console.log("filteredArray2",filteredArray);
    return this.http.get(this.env.API_URL1+'api/updateTransferredQtyInSalesOrderItem',
      {
        params:{
         filteredArray:filteredArray,
         filteredStockEntryDataForSalesOrder: filteredStockEntryDataForSalesOrder,
         stockEntryDoc: stockEntryDoc
        }
      }
      );
    }

  }   


removeRowInStockEntryData(row){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var row = row;
    return this.http.get(this.env.API_URL1+'api/removeRowInStockEntryData',
      {
        params:{
          row:row,
          
        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var row = row;
       
    return this.http.get(this.env.API_URL1+'api/removeRowInStockEntryData',
      {
        params:{
         row:row,
         
        }
      }
      );
    }

  }   

  createStockEntryReportRows(stockEntryReport){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var stockEntryReport = stockEntryReport;
    return this.http.get(this.env.API_URL1+'api/createStockEntryReportRows',
      {
        params:{
          stockEntryReport:stockEntryReport,
          

        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var idx = idx;
    var pickedQty = pickedQty;
    return this.http.get(this.env.API_URL1+'api/createStockEntryReportRows',
      {
        params:{
          stockEntryReport:stockEntryReport,
        }
      }
      );
    }

  } 

  getStockEntryReportRows(salesOrderNo){
       this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
    var salesOrderNo = salesOrderNo;
    return this.http.get(this.env.API_URL1+'api/getStockEntryReportRows',
      {
       params:{
          salesOrderNo:salesOrderNo,
        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
       var salesOrderNo = salesOrderNo;
    return this.http.get(this.env.API_URL1+'api/getStockEntryReportRows',
      {
        params:{
          salesOrderNo:salesOrderNo,
        }
        
      }
      );
    }

  } 

  postStockEntryData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postStockEntryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postStockEntryData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    } 

  getStockDetailsForItemInJV(itemcode){
     this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,19);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
      console.log("this.ipaddress",this.ipaddress);
     var itemcode = itemcode;
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForItemInJV',
      {
        params:{
          itemcode:itemcode,
        }
      }
      );
    }
    else{
      console.log("this.ipaddress",this.ipaddress);
      var itemcode = itemcode;
    return this.http.get(this.env.API_URL2+'api/getStockDetailsForItemInJV',
      {
        params:{
          itemcode:itemcode,
        }
      }
      );
    }

  }

  getStockDetailsFromERPNEXT(name){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var report_no = report_no;
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsFromERPNEXT',
    {
    params:{
    name:name,
    
    }
    });

    }
    else{
    var report_no = report_no;
    
    return this.http.get(this.env.API_URL2+'api/getStockDetailsFromERPNEXT',
    {
    params:{
    name:name,
   
    }
    });

    }
   
    }

    getQICCReportFromERPNEXT(report_no, roll_no){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var report_no = report_no;
    var roll_no = roll_no;
    return this.http.get(this.env.API_URL1+'api/getQICCReportFromERPNEXT',
    {
    params:{
    report_no:report_no,
    roll_no: roll_no
    }
    });

    }
    else{
    var report_no = report_no;
    var roll_no = roll_no;
    return this.http.get(this.env.API_URL+'api/getQICCReportFromERPNEXT',
    {
    params:{
    report_no:report_no,
    roll_no: roll_no
    }
    });

    }
   
    }

    getRollReportFromERPNEXT(roll_no, report_no){
        this.localIP = window.location.origin;
        console.log("localIP",this.localIP);
        this.local_IP = this.localIP.slice(7,20);
        this.ipaddress = this.local_IP;
        if(this.ipaddress != "172.16.18.39"){
        var roll_no = roll_no;
        return this.http.get(this.env.API_URL1+'api/getRollReportFromERPNEXT',
        {
        params:{
        roll_no: roll_no,
        report_no: report_no
        }
        });

        }
        else{
        var roll_no = roll_no;
        return this.http.get(this.env.API_URL+'api/getRollReportFromERPNEXT',
        {
        params:{
        roll_no: roll_no,
        report_no: report_no
        }
        });

        }

    }

    getRollProductionFromERPNEXT(roll_no){
        this.localIP = window.location.origin;
        console.log("localIP",this.localIP);
        this.local_IP = this.localIP.slice(7,20);
        this.ipaddress = this.local_IP;
        if(this.ipaddress != "172.16.18.39"){
        var roll_no = roll_no;
        return this.http.get(this.env.API_URL1+'api/getRollProductionFromERPNEXT',
        {
        params:{
        roll_no: roll_no
        }
        });

        }
        else{
        var roll_no = roll_no;
        return this.http.get(this.env.API_URL+'api/getRollProductionFromERPNEXT',
        {
        params:{
        roll_no: roll_no
        }
        });

        }

    }


  getPOProcessLossFromDB(po_no){
        this.localIP = window.location.origin;
        console.log("localIP",this.localIP);
        this.local_IP = this.localIP.slice(7,20);
        this.ipaddress = this.local_IP;
        if(this.ipaddress != "172.16.18.39"){
        var po_no = po_no;
        return this.http.get(this.env.API_URL1+'api/getPOProcessLossFromDB',
        {
        params:{
        po_no: po_no
        }
        });

        }
        else{
        var po_no = po_no;
        return this.http.get(this.env.API_URL+'api/getPOProcessLossFromDB',
        {
        params:{
        po_no: po_no
        }
        });

        }

    }

    postMistakeData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postMistakeData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postMistakeData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }

    postCCMistakeData(jsonData){
    this.localIP = window.location.origin;
    console.log("localIP",this.localIP);
    this.local_IP = this.localIP.slice(7,20);
    this.ipaddress = this.local_IP;
    if(this.ipaddress != "172.16.18.39"){
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL1+'api/postCCMistakeData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
    else{
    var jsonData = jsonData;
    return this.http.get(this.env.API_URL+'api/postCCMistakeData',
    {
    params:{
    jsonData:jsonData
    }
    });

    }
   
    }


  
 

}